<template lang="html">
  <div class="component-wrapper">
    <Tinder>  </Tinder>
  </div>
</template>

<script>
import Tinder from '@/components/tinder/Tinder.vue'
export default {
    components: {Tinder},
    mounted() {
    }
}
</script>

<style lang="css">
</style>
