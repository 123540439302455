<template lang="html">
    <div class="component-wrapper">
        <div v-if="this.$route.name === 'SinglePlaylistRoute'" class="owner-header">
            <p id="playlist-owner">
                <a id="playlist-owner-link" :href="`/profile/${playlistOwner}`">
                    {{playlistOwner}}
                </a>
            </p>
        </div>
        <div class="header-line">
            <div @click="showSearchResults = !showSearchResults">
                <h1 v-if="showSearchResults" class="search-header">{{header}}▼</h1>
                <h1 v-else-if="!showSearchResults" class="search-header">{{header}}▲</h1>
            </div>
        </div>
        <div v-if="searchResults" class="searchResultsDiv conainer-fluid">
            <div v-if="searchResults.results.length !== 0 && showSearchResults" class="row">
                <a class="search-result mb-3"
                   v-for="(result, index) in searchResults.results"
                   :key="index"
                   :href="'/podcast/' + result.podcast_id"
                   @mouseover="currentlyHovered=result.podcast_id"
                   @mouseleave="currentlyHovered=null">
                    <div class="single-podcast-header-text col-md-3 col-10">
                        <p class="results-podcast-name">
                            <strong>
                                {{result.name}}
                            </strong>
                        </p>
                        <p class="result-artist-name">
                            {{result.artist}}
                        </p>
                    </div>
                    <div class="col-md-7 d-none d-md-block p-2 result-summary-div" v-if="header === 'Similar podcasts' || header.endsWith(`'s podcasts`)">
                        <div class="results-podcast-summary">
                            <small v-if="result.summary.length > 200">
                                {{result.summary.slice(0, 197)}}...
                            </small>
                            <small v-else>
                                {{result.summary}}
                            </small>
                        </div>
                    </div>
                    <div class="col-md-7 d-none d-md-block p-2 result-summary-div" v-else-if="header === 'Popular podcasts'">
                        <div class="results-podcast-summary">
                            <small v-if="result.summary.length > 200">
                                {{result.summary.slice(0, 197)}}...
                            </small>
                            <small v-else>
                                {{result.summary}}
                            </small>
                        </div>
                    </div>
                    <div class="col-md-7 d-none d-md-block p-2 result-summary-div" v-else>
                        <div class="results-podcast-summary">
                            <small>
                                {{result.summary}}
                            </small>
                        </div>
                    </div>
                    <div v-if="result.podcast_id" id="single-podcast-header-image" class="col-md-2 d-flex col-2 justify-content-end">
                        <b-img rounded fluid class="result-image"
                               :src="require(`@/assets/img/${result.podcast_id}.jpg`)"
                               @error="$event.target.src('https://cdn.pixabay.com/photo/2020/02/12/05/13/dog-cartoon-4841702_960_720.jpg')">
                        </b-img>
                    </div>
                </a>
            </div>
            <div v-else-if="searchResults.count === 0">
                <h4>No results found for "<strong>{{this.$store.state.searchQuery}}</strong>"</h4>
                <h3>Try looking for something else</h3>
            </div>
            <div v-if="searchResults.results.length === 0 && showSearchResults" class="genre-scores-container-profile d-block">
                <p id="nothing-here-yet">Nothing here! Yet.</p>
            </div>
            <div v-if="this.$route.name === 'searchResultsRoute'" class="pagination-buttons d-flex">
                <div v-if="this.previous" class="previous-button pagination-button" @click="decrementSearchResults">
                    <p>
                        Previous
                    </p>
                </div>
                <div v-if="this.next" class="next-button pagination-button" @click="incrementSearchResults">
                    <p>
                        Next
                    </p>
                </div>

            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'SearchResults',
    props: {
        searchResultsData: {
            required: true,
            type: Object
        },
        header: {
            required: true,
            type: String
        },
        resultsType: {
            required: false,
            type: String
        },
        playlistOwner: {
            required: false,
            type: String
        },
    },
    data() {
        return {
            page: 1,
            fullSearchResults: ['Popular podcasts', 'Playlist'],
            showSearchResults: true,
            currentlyHovered: null
        }
    },
    computed: {
        searchResults() {
            return this.searchResultsData

        },
        next() {
            return this.searchResults.next
        },
        previous() {
            return this.searchResults.previous
        }
    },
    methods: {
        incrementSearchResults() {
            this.$http.get(this.next).then((response) => {
                // this.next = response.data.next
                // this.previous = response.data.previous
                this.searchResultsData = response.data
                window.scrollTo(0, 0)
            })
        },
        decrementSearchResults() {
            this.$http.get(this.previous).then((response) => {
                // this.next = response.data.next
                // this.previous = response.data.previous
                this.searchResultsData = response.data
                window.scrollTo(0, 0)
            })
        }
    },
}
</script>

<style lang="css">
    .result-image {
        height: 100%;
        width: auto;
        max-width:100%;
        max-height:100%;
    }
    .search-result {
        padding: 0.4em;
        display: flex;
        cursor: pointer;
        text-decoration: none;
        color: white;
        border-radius: 20px;
        box-shadow: var(--globalButtonShadow);
        max-height: 10vh;
    }
    .search-result:hover {
        background: var(--hoverColor);
        color: white;
    }
    .results-podcast-summary {
        display: inline-block;
        position: relative;
        word-wrap: break-word;
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .results-podcast-name {
        font-weight: bolder;
        text-decoration:none;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .results-podcast-summary {

    }
    .single-podcast-header-text {
    }
    .search-result {
        position: relative;
    }
    .searchResultsDiv {
    }
    .search-header {
        font-size: 1.5em;
        cursor: pointer;
        margin-bottom: 0.4em;
        color: white;
    }
    #single-podcast-header-image {
        align-items: center;
    }
    .header-line {
        justify-content: center;
    }
    .remove-from-playlist {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        font-size: 1em;
        color: white;
        background: #bf3b3b;
        padding-left: 0.4em;
        padding-right: 0.4em;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        border-radius: 0.2em;

    }
    .remove-from-playlist p {
        margin: 0px !important;
        padding: 0px !important;
    }
    .result-artist-name {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    #playlist-owner a {
        color: white !important;
    }
    .genre-scores-container-profile {
        box-shadow: var(--globalButtonShadow);
        border-radius: 1em;
        padding: 1em;
    }
    .next-button {
        margin-left: auto;
    }
    .previous-button {
        margin-right: auto;
    }
    .pagination-button:active {
        transform: translateY(4px);
    }
    .pagination-button:hover {
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        background: var(--hoverColor);
    }
    .pagination-button {
        background: var(--lightBackground);
        width: 5em;
        border-radius: 0.4em;
        padding: 0.4em;
        display: flex;
        justify-content: center;
        box-shadow: var(--globalButtonShadow);
        margin-bottom: 1em;
        cursor: pointer;
        color: white;
    }
    #nothing-here-yet {
        color: white;
    }
</style>
