<template lang="html">
    <div class="component-wrapper">
        <div v-if="!showInstructions">
            <div v-if="!finished && podcastQueue.length &lt; 2">
                <p style="font-size:50px">Loading...</p>
                <div class="spinner-border text-light" style="width: 3rem; height: 3rem;" role="status">
                </div>
            </div>
            <div v-if="!finished && podcastQueue" class="tinder-container">
                <div class="podcast-cards-container">
                    <div class="podcast-card" v-for="(podcast) in podcastQueue" :key="podcast.podcast_id.toString() + totalActions.toString()">
                        <vue-swing @throwoutright="likePodcast(podcast)"
                                   @throwoutleft="dislikePodcast(podcast)"
                                   ref="swing"
                                   :config="swingConfig"
                                   @dragmove="setDragDirection($event)"
                                   @dragend="resetDragDirection">
                            <div class="card-wrapper d-flex justify-content-center">
                                <div class="current-card p-2"
                                     v-if="podcast && currentPodcast"
                                     :style="applyStyle(podcast)">
                                    <b-img fluid rounded class="podcast-image" :src="require(`@/assets/img/${podcast.podcast_id}.jpg`)" alt=""></b-img>
                                    <p class="podcast-title">{{podcast.name}}</p>
                                    <p class="podcast-artist">{{podcast.artist}}</p>
                                </div>
                            </div>
                        </vue-swing>
                    </div>
                </div>
                <div v-if="podcastQueue" class="button-container d-flex">
                    <div class="card-button dislike-button" @click="emitDislike(buttonDislike=true)">
                        <p>👎</p>
                    </div>
                    <div class="card-button like-button" @click="emitLike(buttonLike=true)">
                        <p>👍</p>
                    </div>
                    <div v-bind:class="previousPodcast ? 'none': 'disabled-click'" class="card-button undo-button" @click="undo()">
                        <p>↩️</p>
                    </div>
                </div>
                <b-progress :max="stepsToComplete" height="2rem">
                    <b-progress-bar :value="liked">
                        <span><strong>{{ liked }} / {{ stepsToComplete }} liked</strong></span>
                    </b-progress-bar>
                </b-progress>
            </div>
            <div v-else-if="finished">
                <p id="found-n-podcasts">Found {{this.finalMatches.length}} audio dramas you might like</p>
                <SearchResults :header="'Based on your preferences'"
                               :searchResultsData="{results: this.finalMatches}">
                </SearchResults>
            </div>
        </div>
        <div v-else-if="showInstructions">
            <b-modal centered
                     hide-backdrop
                     hide-header
                     ok-title="Got it!"
                     cancel-title="Don't show again"
                     v-model="showInstructions"
                     @ok="started=true"
                     @cancel="dontShowInstructionsAgain"
                     ref="instructions-modal"
                     id="instructions-modal">

                <p id="instructions-header">Find your perfect audio drama match</p>
                <hr class="mt-0 mb-3">
                <p id="instructions">Swipe through some of the best fictional podcasts to find others you might grow to love!</p>
            </b-modal>
        </div>

  </div>
</template>

<script>
import SearchResults from '@/components/search_results/SearchResults.vue'
import VueSwing from 'vue-swing'

export default {
    components: {
        SearchResults,
        VueSwing
    },
    data() {
        return {
            podcastQueue: [],
            nextPodcast: null,
            previousPodcast: null,
            likedPodcasts: [],
            dislikedPodcasts: [],
            skippedPodcasts: [],
            lastAction: '',
            seenIds: [],
            possibleMatchesIds: {},
            possibleMatches: [],
            stepsToComplete: 10,
            steps: 0,
            totalActions: 0,
            dragDirection: null,
            podcastBeingDragged: null,
            podcastBeingLiked: null,
            podcastBeingDisliked: null,
            started: false,
            scores: {
                horrorScore: 0,
                thrillerScore: 0,
                mysteryScore: 0,
                sciFiScore: 0,
                fantasyScore: 0,
                crimeScore: 0,
                comedyScore: 0,
                folkloreScore: 0
            },
            swingConfig: {
                allowedDirections: [
                    VueSwing.Direction.LEFT,
                    VueSwing.Direction.RIGHT
                ],
                minThrowOutDistance: 200,
                maxThrowOutDistance: 500,
                isThrowOut: function(xOffset, yOffset, element, throwOutConfidence) {
                  return throwOutConfidence > 0.5;
                },
            }
        }
    },
    mounted() {
        this.setupPodcastQueue()
    },
    methods: {
        dontShowInstructionsAgain() {
            localStorage.setItem('showSwiperInstructions', 'false')
            this.started = true
        },
        emitLike(buttonLike=false) {
            let currentSwing = this.$refs.swing[0]
            this.podcastBeingDragged = this.currentPodcast.podcast_id
            if (buttonLike) {
                this.podcastBeingLiked = this.currentPodcast.podcast_id
            }
            this.dragDirection = 'right'
            setTimeout(() => {
                currentSwing.$emit('throwoutright')
                this.podcastBeingDragged = null
                this.podcastBeingLiked = null
            }, 600)
            // currentSwing.cards[0].throwOut(-10, 0)
        },
        emitDislike(buttonDislike=false) {
            let currentSwing = this.$refs.swing[0]
            this.podcastBeingDragged = this.currentPodcast.podcast_id
            if (buttonDislike) {
                this.podcastBeingDisliked = this.currentPodcast.podcast_id
            }
            this.dragDirection = 'left'
            setTimeout(() => {
                currentSwing.$emit('throwoutleft')
                this.podcastBeingDragged = null
                this.podcastBeingDisliked = null
            }, 600)
        },
        setupPodcastQueue() {
            Array.from({ length: 3 }, () => {
                this.addRandomPodcastToQueue()
            })
        },
        addRandomPodcastToQueue() {
            let randomQuery = `${this.$store.state.endpoints.baseUrl}random-podcast?timestamp=${new Date().getTime()}/`
            this.$http.get(randomQuery).then((response) => {
                let randomPodcast = response.data.results[response.data.results.length - 1]
                if (!randomPodcast || this.seenIds.includes(randomPodcast.podcast_id)) {
                    return this.addRandomPodcastToQueue()
                }
                this.podcastQueue.unshift(randomPodcast)
            })
        },
        likePodcast() {
            this.lastAction = 'like'
            this.previousPodcast = this.currentPodcast
            this.likedPodcasts.push(this.currentPodcast)
            this.seenIds.push(this.currentPodcast.podcast_id)
            for (let podcast of this.currentPodcast.related_podcasts) {
                if (this.possibleMatchesIds[podcast.podcast_id]) {
                    this.possibleMatchesIds[podcast.podcast_id] += 1
                } else {
                    this.possibleMatchesIds[podcast.podcast_id] = 1
                }
                if (!this.possibleMatches.includes(podcast)) {
                    this.possibleMatches.push(podcast)
                }
            }
            this.podcastQueue.pop()
            this.steps += 1
            this.totalActions += 1
            this.addRandomPodcastToQueue()
        },
        dislikePodcast() {
            this.lastAction = 'dislike'
            this.previousPodcast = this.currentPodcast
            this.dislikedPodcasts.push(this.currentPodcast)
            this.seenIds.push(this.currentPodcast.podcast_id)
            this.steps += 1
            this.totalActions += 1
            this.podcastQueue.pop()
            this.addRandomPodcastToQueue()
        },
        skipPodcast() {
            this.lastAction = 'skip'
            this.previousPodcast = this.currentPodcast
            this.skippedPodcasts.push(this.currentPodcast)
            this.seenIds.push(this.currentPodcast.podcast_id)
            this.podcastQueue.pop()
            this.addRandomPodcastToQueue()
        },
        undo() {
            if (this.lastAction === 'skip') {
                this.skippedPodcasts.pop()
            } else if (this.lastAction === 'like') {
                this.podcastQueue.push(this.likedPodcasts.pop())
            } else {
                this.podcastQueue.push(this.dislikedPodcasts.pop())
            }
            this.previousPodcast = null
            this.steps -= 1
            this.lastAction = 'skip'
        },
        setDragDirection(direction) {
            if (direction.throwDirection.toString() === 'Symbol(RIGHT)') {
                this.dragDirection = 'right'
            } else if (direction.throwDirection.toString() === 'Symbol(LEFT)') {
                this.dragDirection = 'left'
            }
            this.podcastBeingDragged = this.currentPodcast.podcast_id
        },
        resetDragDirection() {
            this.dragDirection = ''
            this.podcastBeingDragged = null
        },
        applyStyle(podcast) {
            let style = this.cardStyle
            if (podcast.podcast_id === this.currentPodcast.podcast_id) {
                style += `margin-right:0.6em;`
            }
            if (podcast.podcast_id === this.podcastBeingDragged) {
                style += `-webkit-transition-duration: 0.8s;
                          transition-duration: 0.8s;`
                if (this.dragDirection === 'right' && podcast.podcast_id === this.podcastBeingLiked) {
                    style += `transform: translateX(150%) rotate(15deg);`
                } else if (this.dragDirection === 'left' && podcast.podcast_id === this.podcastBeingDisliked) {
                    style += `transform: translateX(-150%) rotate(-15deg);`
                }
                return style
            } else {
                style += `background: rgba(67, 99, 168, 1);`
            }
            return style
        }
    },
    computed: {
        showInstructions: {
            get() {
                if (localStorage.showSwiperInstructions === 'false' || this.started) {
                    return false
                } else {
                    return true
                }
            },
            set() {
                return 0
            }
        },
        finished() {
            return this.liked >= this.stepsToComplete && this.finalMatches.length > 1
        },
        likelyMatchesIds() {
            let likelyMatches = Object.entries(this.possibleMatchesIds).sort((a, b) => b[1] - a[1])
            return Object.fromEntries(likelyMatches.filter(pod => pod[1] > 1))
        },
        finalMatches() {
            if (this.likelyMatchesIds) {
                let x = this.possibleMatches.filter(pod =>
                    Object.keys(this.likelyMatchesIds)
                    .includes(String(pod.podcast_id)))
                return x.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            } else {
                return []
            }
        },
        liked() {
            return this.likedPodcasts.length
        },
        cardStyle() {
            if (this.dragDirection === 'right') {
                return `background: #5fac5f;`
            } else if (this.dragDirection === 'left') {
                return `background: #b46060;`
            } else {
                return `background: rgba(67, 99, 168, 1);`
            }
        },
        currentPodcast() {
            return this.podcastQueue[this.podcastQueue.length -1]
        }
    }
}
</script>

<style lang="css">
    .current-card {
        height: 30vw;
        width: 20vw;
        box-shadow: var(--globalButtonShadow);
        border-radius: 1em;
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        color: white;
    }
    .podcast-image {
        margin: auto;
    }
    .podcast-title {
        font-size: 1.4em;
        word-wrap: break-word;
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .podcast-artist {
        word-wrap: break-word;
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .card-button {
        width: 6em;
        padding: 0.4em;
        margin-bottom: 1em;
        border-radius: 0.4em;
        margin-right: 0.2em;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .card-button:active {
        transform: translateY(4px);
        -webkit-transition-duration: 0.1s;
        transition-duration: 0.1s;
    }
    .like-button {
        box-shadow: var(--globalButtonShadow);
        background: #6998ff;
    }
    .dislike-button {
        box-shadow: 2px 2px 2px #7a3535,
            -1px -1px 3px #be5555;
        border: none;
        background: #be5555;
        color: white;
    }
    .skip-button {
        box-shadow: var(--globalButtonShadow);
        background: #ebebeb;
    }
    .undo-button {
        box-shadow: 2px 2px 2px #9a8323,
            -1px -1px 3px #d4b533;
        background: #d4b533;
    }
    .undo-button p {
        color: rgb(45, 45, 45) !important;
    }
    .skip-button p {
        color: rgb(45, 45, 45) !important;
    }
    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 2em;
    }
    .disabled-click {
        pointer-events:none;
        opacity: 0.3;
    }
    .disabled-click p {
        color: white !important;
    }
    .progress .progress-bar {
        background: var(--lightBackground);
    }
    .progress {
        margin-bottom: 1em;
        border-radius: 0.6em;
        width: 20vw;
        margin: auto;
        background: #d0d2e4;
    }
    @media (max-width: 519px) {
        .current-card {
            height: 60vh;
            width: 90vw;
        }
        .progress {
            width: 90vw;
        }
    }
    .podcast-cards-container {
        display: grid;
        justify-content: center;

    }
    .podcast-card {
        grid-column: 1;
        grid-row: 1;

    }
    #instructions-modal___BV_modal_content_ {
        box-shadow: var(--globalButtonShadow);
    }
    .modal-footer {
        border: none !important;
    }
    #instructions-modal___BV_modal_footer_ > button {
        background: var(--hoverColor);
        box-shadow: 1px 1px 1px var(--darkBackground),
            -1px -1px 1px var(--hoverColor);
        border: none;
    }
    #instructions-modal___BV_modal_footer_ > button.btn.btn-secondary {
        background: rgb(147, 147, 147);
        box-shadow: 1px 1px 1px #737372,
                    -1px -1px 1px #bbbbbb;
        border: none;
    }
    #instructions-modal___BV_modal_footer_ {
        padding: none;
        padding-bottom: 0.75rem;
    }

    #instructions-modal___BV_modal_body_ {
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 0rem;
    }

    #instructions-header {
        font-size: 1em;
        font-weight: bold;
    }
    #instructions-modal___BV_modal_content_ {
        color: var(--darkBackground) !important;
        padding-left: 1em;
        padding-right: 1em;
    }
    #instructions-header {

    }
    #instructions {
        color: var(--darkBackground);
    }
    #instructions-header  {
        color: var(--darkBackground);
    }
    #found-n-podcasts {
        color: white;
    }
</style>
